
import React from 'react';
import moment from 'moment/moment';

/**
 * This would make for a great React component to test
 */

const daysBetween = (date_1, date_2) => {
  let difference = date_1.getTime() - date_2.getTime();
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
}

const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

const advertisementConfig = {
  currentDate: '',
  launchDateStart: '',
  launchDateEnd: '',
  lastPeriodStart: '',
  lastPeriodEnd: '',
  adType: '' // discount, launch
}

export class AdvertisementController {

  constructor (config) {
    this.currentDate = config.currentDate ? config.currentDate : new Date()
    this.launchDateStart = config.launchDateStart ? config.launchDateStart : new Date('Dec 20 2024')
    this.launchDateEnd = config.launchDateEnd ? config.launchDateEnd : new Date('Dec 20 2024 23:58:00');
    this.lastPeriodStart = config.lastPeriodStart ? config.lastPeriodStart : new Date('Dec 29 2024 00:00:00'); 
    this.lastPeriodEnd = config.lastPeriodEnd ? config.lastPeriodEnd : new Date('Dec 29 2024 23:59:00'); 
    this.adType = config.adType ? config.adType : 'launch'
  }

  getDaysBetween (firstDate, secondDate) {
    firstDate = firstDate ? firstDate : this.launchDateStart
    secondDate = secondDate ? secondDate : this.currentDate
    return daysBetween(firstDate, secondDate);
  }
  
  getLaunchState () {
    let currentDate = this.currentDate 
    let launchDateStart = this.launchDateStart;
    let launchDateEnd = this.launchDateEnd;

    const isPreRegistration = currentDate.getTime() < launchDateStart.getTime();
    const isPreLaunch = currentDate.getTime() >= launchDateStart.getTime() && currentDate <= launchDateEnd.getTime();
    const isEndLaunch = currentDate.getTime() >= launchDateEnd.getTime();
    const isBackForLastPeriod = currentDate.getTime() === this.lastPeriodStart.getTime();
    const launchDay = datesAreOnSameDay (currentDate, launchDateStart);
    
    if (launchDay) return 'is-launch-day'
    if (isBackForLastPeriod) return 'launch-last-period'
    if (isEndLaunch) return 'pre-launch-ended'
    if (isPreRegistration) return 'pre-registration';
    if (isPreLaunch) return 'pre-launch';
  }

  getAdType () {
    return this.adType;
  }

  getEndLaunchDateString () {
    return moment(this.endLaunchDate).format('MMM D, YYYY')
  }

  getTitleSubtitle () {
    let launchState = this.getLaunchState();
    let currentDaysBetween = this.getDaysBetween(this.launchDate, this.currentDate);
    let hasLaunched = currentDaysBetween >= 0;
    let isOneDayBeforeLaunch = currentDaysBetween === 1;
    let daysBetweenNowAndEnd = this.getDaysBetween(this.launchDateEnd, this.currentDate);
    let daysBetweenLastLaunch = this.getDaysBetween(this.lastPeriodEnd, this.currentDate);
    let title = ""

    const testingMasteryDiscount = { 
      LastDay: `Master testing once and for all. Get a 25% off discount on my new Testing Mastery course. Ends at midnight (EST) today.`,
      DaysUntil: `Master testing once and for all. Get a 25% off discount on my new Testing Mastery course. Ends in ${daysBetweenNowAndEnd} days.`,
      Copy: <>
      Most devs struggle with testing! Learning how to write useful tests (on any side of the stack), is without a doubt, the first skill every developer can master to get on track with what <i>actually</i> matters, and propel yourself forward to take on 10x more difficult problems.<br/><br/>  

      Today, I'm proud to announce the launch of "Testing Mastery": The Software Essentialist Bachelors Program, where you'll learn and practice writing powerful tests on any side of the stack, starting from first principles. <br/><br/> 
      
      Before the end of the month (October 31st), you can get the entire suite of courses in the program for 25% off using the discount code TESTING-MASTERY. <a href="https://www.essentialist.dev/offers/bU72esPL/checkout?coupon_code=TESTING-MASTERY">Enroll here.</a><br/><br/> 

      Because despite what it seems like, you don't have to burn out trying to keep up with all the latest JavaScript libraries and frameworks. You don't have to learn everything. You just have to <b>master what matters</b>. <br/><br/> 
      
      All the best, and as always, To Mastery!</>
    }
    const christmasDiscount = {
      LastDay: `Merry Christmas Sale! 🎄 Ends at midnight (EST) today.` ,
      DaysUntil: `Merry Christmas Sale! 🎄 Ends in ${daysBetweenNowAndEnd} days.`,
      Copy: <>
      Friends, what an incredible year it has been. If I know us well, we're always moving forward. We're following our curiosity. And most importantly, we're growing. <br/><br/> Never forget one of the most important success techniques there is: reflection. So, take a moment to look back on how far you've come this year. Go on and collect the lessons, get inspired, and prepare for a prosperous 2025.<br/><br/>  
      
      Before December 29th, you can get 50% off of Testing Mastery <a href="https://www.essentialist.dev/testing-mastery">using the discount code XMAS-50</a> and 10% off the entire suite of courses in the academy <a href="https://www.essentialist.dev/the-software-essentialist">using the discount code TSE-XMAS.</a><br/><br/>

      Thank you, and may 2025 grant you the growth, love & power to create what matters most in your world<br/><br/> 
      
      Enjoy your holidays. See you next year. <br/><br/> And always, To Mastery!</>
    }

    if (this.getAdType() === 'discount') {
      if (daysBetweenNowAndEnd === 0) {
        title = christmasDiscount.LastDay
      } else {
        title = christmasDiscount.DaysUntil
      }
      
      return {
        title: title,
        subtitle: christmasDiscount.Copy
      }
    }


    if (launchState === 'launch-last-period') {
      return {
        title: `Last call. Registrations close April 13th @ midnight`,
        subtitle: (<>We've re-opened registration for a bit longer. <i>The Software Essentialist</i>: the ultimate course for professional TypeScript developers closes @ midnight, April 13th.</>)
      } 
    }
    
    if (launchState === 'pre-registration' && !isOneDayBeforeLaunch) {
      return {
        title: `55% off The Software Essentialist sale - doors open in ${currentDaysBetween} days`,
        subtitle: ('')
      }
    }
    if (isOneDayBeforeLaunch) {
      return {
        title: `Get ready... tomorrow...`,
        subtitle: (<>I'm opening registrations for <i>The Software Essentialist</i>: the ultimate course for professional TypeScript developers tomorrow (March 31st).</>)
      }
    }

    if (hasLaunched && launchState !== 'isEndLaunch') {
      return {
        title: `It's finally here, registration ends in ${daysBetweenNowAndEnd} days`,
        subtitle: (<>We've just opened registrations for a 55% off sale of <i>The Software Essentialist</i>: the ultimate course for professional TypeScript, React & Node.js developers.</>)
      }
    }

    if (daysBetweenLastLaunch === 0) {
      return {
        title: `Registration closes tomorrow (> 24hrs)`,
        subtitle: (<>Registrations for <i>The Software Essentialist</i>: the ultimate course for professional TypeScript developers ends tomorrow at midnight, April 13th.</>)
      }
    }

    return {
      title: `It's finally here, registration ends in ${daysBetweenNowAndEnd} days`,
      subtitle: (<>We've just opened registrations for the 55% off sale of <i>The Software Essentialist</i>: the ultimate course for professional TypeScript, React & Node.js developers.</>)
    }
  }

}

