import React from "react";
import solidbook from "../../../images/resources/solidbook-wiki.png";
import testingMastery from "../../../images/resources/testing-mastery.png";
import theSoftwareEssentialist from "../../../images/resources/the-software-essentialist.jpg";
import "../styles/Resources.sass";

const resources = [
  {
    title:
      "solidbook: An Introduction to the World of Software Design, Architecture & Testing",
    image: solidbook,
    description: `Get clarity. Uncover the unknown unknowns and learn the main topics from over the last 40 years of software design, architecture & testing.`,
    ctaText: `Get the wiki/book`,
    ctaURL: "https://solidbook.io/",
  },
  // {
  //   title: "The Phases of Craftship - Free",
  //   image: phasesOfCraftship,
  //   description: `Beat burnout, imposter syndrome and information overload. An introduction to the five Phases of Craftship: a straight-line path through the "hidden apprenticeship" every developer must take towards mastering what matters.`,
  //   ctaText: `Download the course`,
  //   ctaURL: "https://www.essentialist.dev/offers/hN4GMJcp",
  // },
  {
    title: "Testing Mastery",
    image: testingMastery,
    description: `Master the first skill you should have been taught in school: testing. Takes you through the first two Phases of Craftship.`,
    ctaText: `Enroll in TM`,
    ctaURL: "https://www.essentialist.dev/testing-mastery",
  },
  {
    title: "The Software Essentialist",
    image: theSoftwareEssentialist,
    description: `Become a confident crafter. Master the 80-20 of testing, design, architecture & strategy to write scalable, testable, flexible and maintainable code on any side of the stack. Takes you through all five Phases of Craftship.`,
    ctaText: `Enroll in TSE`,
    ctaURL: "https://www.essentialist.dev/the-software-essentialist",
  },
];

const Resources = ({ displayHeader }) => {
  return (
    <div className="resources">
      {displayHeader ? (
        <>
          <h2 id="resources">Resources</h2>{" "}
          <p>Go from coder to systems thinker.</p>
        </>
      ) : (
        ""
      )}
      {resources.map((resource, key) => (
        <div key={key} className="resource-container">
          <div className="resource-info-container">
            <h3>{resource.title}</h3>
            <p>{resource.description}</p>
            <a href={resource.ctaURL}>{resource.ctaText}</a>
          </div>
          <div className="resource-product-image">
          <a href={resource.ctaURL}>
            <img src={resource.image} />
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Resources;
